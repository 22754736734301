<template>
  <v-container fluid :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-account-group-outline</v-icon>
        Leads
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-4 py-8">
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
            v-for="(usuario, index) in usuarios"
            :key="index"
          >
            <v-card
              @click="goToUser(usuario)"
              flat
              color="#F5F5F5"
              class="pa-3"
              :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
            >
              <div class="d-flex align-center justify-center">
                <v-avatar width="100px" height="100px">
                  <v-img
                    v-if="usuario.avatar"
                    cover
                    :src="usuario.avatar"
                  ></v-img>
                  <v-img
                    v-else
                    contain
                    :src="require('@/assets/avatar_placeholder.png')"
                  ></v-img>
                </v-avatar>
              </div>

              <div
                style="width: 100%"
                class="pl-5 py-3 pr-1 d-flex flex-column align-start justify-center"
              >
                <span class="font-weight-bold text-uppercase">
                  {{ usuario.nome | excerpt(25) }}
                </span>
                <span class="text-body-2">{{ usuario.email }}</span>

                <v-divider class="my-2"></v-divider>

                <div class="d-flex align-center justify-start">
                  <v-icon small left>mdi-text-box-outline</v-icon>
                  <span class="text-body-2">{{ usuario.documento }}</span>
                </div>

                <div class="d-flex align-center justify-start">
                  <v-icon small left>mdi-whatsapp</v-icon>
                  <span class="text-body-2">{{ usuario.celular }}</span>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col v-if="usuarios.length == 0"> Sem informações dos Leads </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <DialogLead
      v-if="dialogLeads"
      :dialogLeads.sync="dialogLeads"
      :item="usuario"
    />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { getLeadsStaff } from "@/api/staff/leeds.js";
export default {
  name: "Leads",

  components: {
    DialogLead: () => import("./components/DialogLead.vue"),
  },

  data() {
    return {
      loading: true,
      usuarios: [],
      dialogLeads: false,
      usuario: {},
    };
  },

  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
  },

  methods: {
    goToUser(item) {
      this.usuario = { ...item };
      this.dialogLeads = true;
    },
    getUsuarioLeads() {
      this.loading = true;
      return getLeadsStaff(this.user_id)
        .then((response) => {
          this.usuarios = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getUsuarioLeads();
  },
};
</script>

<style></style>
