import api from "../axios_service.js";

export async function getLeadsStaff(id) {
  const response = await api.get(`/staff/leads/${id}`);
  return response.data.result;
}




